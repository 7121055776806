import React from 'react'
import SkewBackground from 'components/SkewBackground'
import { theme } from '../../twconfig'
import viewportMotion from 'components/viewport-motion'
import TextTransition from '../text-transition'
import { emptyVariants } from '../../util'
import { SquaresBackgroundSvg } from '../../templates/home'
// import useGatsbyData from '../../hooks/use-gatsby-data'

const PhilosophySection = ({ id, parallaxY, data, ...props }) => (
  <section id={id} data-loadable-fallback={`section-${id}`}>
    <SkewBackground
      className="flex flex-col justify-center py-12"
      bgColor={theme.colors.gray[`600`]}
    >
      {data.columns && (
        <>
          <SquaresBackgroundSvg
            fillColor={`black`}
            className="mt-8 mb-8 md: mt-0"
          >
            <h2 className="section-title">
              <viewportMotion.span
                variants={emptyVariants}
                className="inline-block bg-gray-600 px-4"
              >
                <TextTransition
                  text={data.heading}
                  transition={{ mass: 0.1, stiffness: 25, damping: 25 }}
                />
              </viewportMotion.span>
            </h2>
          </SquaresBackgroundSvg>
          <div className="container lg:my-32">
  
            <div className="container">
              <div className="lg:-mx-10 lg:flex lg:flex-row lg:flex-no-wrap">
                {data.columns.map(({ heading, content }, idx) => {
                  return (
                    <div
                      key={idx}
                      className="lg:flex-1 lg:px-10 lg:w-1/3 lg:text-center"
                    >
                      <viewportMotion.h3
                        className="heading mb-4"
                        dangerouslySetInnerHTML={{ __html: heading }}
                      />
  
                      {content.remark && (
                        <viewportMotion.div
                          dangerouslySetInnerHTML={{
                            __html: content.remark.html,
                          }}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </SkewBackground>
  </section>
)

const PhilosophySectionWithData = ({ data: { page: { philosophy }}, ...props}) => {
  return (
    <PhilosophySection {...props} data={philosophy} />
  )
}

export default PhilosophySectionWithData
